<template>
    <div class="sector">
        <SanackBar
            :activated="snackActivated"
            :success="snackSuccess"
            :message="snackMessage"
            @closeSnackBar="closeSnack()" />

        <div class="form">
            <div class="form-head">
                <h1 class="head-title">{{ titles.sector }}</h1>
            </div>

            <v-chip class="ma-2" color="primary" v-if="isEditionMode">
                <v-icon left> mdi-database </v-icon>
                id: {{ emotion.id }}
            </v-chip>

            <div class="form-fields">
                <div class="field-box field-name">
                    <label>Nome</label>
                    <v-text-field
                        v-model="emotion.name"
                        ref="name"
                        type="name"
                        filled
                        rounded
                        dense>
                    </v-text-field>
                </div>

                <div class="field-box field-preposition">
                    <label>Preposição</label>
                    <v-text-field
                        v-model="emotion.preposition"
                        ref="preposition"
                        type="name"
                        filled
                        rounded
                        dense>
                    </v-text-field>
                </div>

                <div class="field-box field-submit">
                    <SubmitFormButton
                        :title="titles.submitButton"
                        :disabled="formSubmited"
                        @submitForm="submitForm()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import SanackBar from '@/components/snack/SanackBar.vue';
    import SubmitFormButton from '@/components/SubmitFormButton.vue';
    import { filterPropertiesInObject } from '@/utils/filterProperties';
    import { generateSlug } from '@/modules/admin/helpers/generateSlug';
    import { parseDTO } from '@/utils/parseDTO';

    export default {
        name: 'FormEmotion',
        components: { SanackBar, SubmitFormButton },
        data() {
            return {
                isEditionMode: false,
                snackActivated: false,
                snackSuccess: false,
                snackMessage: null,
                formSubmited: false,
                emotion: {
                    id: null,
                    iconFile: 'iconx',
                    name: null,
                    slug: null,
                    preposition: null
                },
                titles: {
                    sector: null,
                    submitButton: null
                }
            };
        },
        mounted() {
            this.checkIsEditionMode();
        },
        watch: {
            emotion: {
                handler(e) {
                    this.monitoreEmotionProperties(e);
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters({
                emotionStore: 'getDataEmotion'
            }),
            monitoreEmotionProperties() {
                return (e) => {
                    e.slug = e.name ? generateSlug(e.name) : null;
                };
            }
        },
        methods: {
            async checkIsEditionMode() {
                if (this.emotionStore.isEditionMode) {
                    this.titles.sector = 'Editar emotion';
                    this.titles.submitButton = 'Atualizar';

                    this.isEditionMode = true;

                    this.emotion.id = this.emotionStore.id;

                    await this.serviceLoadingEmotion();
                } else {
                    this.titles.sector = 'Criar emotion';
                    this.titles.submitButton = 'Criar';
                }
            },
            activeSnack(success, message) {
                this.snackActivated = true;
                this.snackSuccess = success;
                this.snackMessage = message;
            },
            closeSnack() {
                this.snackActivated = false;
            },
            goToAfterSubmitForm() {
                this.$router.push({ name: 'emotionsAdmin' });
            },
            validateSubmitForm() {
                const snack = (message) => this.activeSnack(false, message);

                const validateEmotionName = (name) => {
                    if (!name) {
                        snack('Preencha o campo nome!');
                        return true;
                    }

                    if (name.length < 4) {
                        snack('O nome deve conter no mínimo 4 caracteres!');
                        return true;
                    }

                    if (name.length > 16) {
                        snack('O nome deve conter no máximo 16 caracteres!');
                        return true;
                    }

                    return false;
                };

                const validateEmotionPreposition = (preposition) => {
                    if (!preposition) {
                        snack('Preencha o campo preposição!');
                        return true;
                    }

                    if (preposition.length < 2) {
                        snack('A preposição deve conter no mínimo 2 caracteres!');
                        return true;
                    }

                    if (preposition.length > 8) {
                        snack('A preposição deve conter no máximo 8 caracteres!');
                        return true;
                    }

                    return false;
                };

                const errors = [
                    validateEmotionName(this.emotion.name),
                    validateEmotionPreposition(this.emotion.preposition)
                ];

                return !errors.some((e) => e);
            },
            filterObject(emotion) {
                const propertiesToFilter = [
                    'id',
                    'iconFile',
                    'name',
                    'slug',
                    'preposition'
                ];

                return filterPropertiesInObject(emotion, propertiesToFilter);
            },
            async serviceLoadingEmotion() {
                this.$store.commit('setGlobalLoading', true);

                const emotionId = this.emotionStore.id;

                this.$service.http['admin']
                    .getEmotion(emotionId)
                    .then((result) => {
                        const record = result.data.doc;

                        const recordsPropertiesFiltered = this.filterObject(record);

                        const dataParsed = parseDTO(recordsPropertiesFiltered);

                        this.emotion = dataParsed;
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            async serviceUpdateEmotion() {
                await this.$service.http['admin']
                    .updateEmotion(this.emotion.id, this.emotion)
                    .then((_) => {
                        this.activeSnack(true, 'Emotion atualizado!');

                        setTimeout(() => {
                            this.goToAfterSubmitForm();
                        }, 3000);
                    })
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack(
                            false,
                            'Ocorreu um erro no processamento do endpoint!'
                        );
                    });
            },
            async serviceCreateEmotion() {
                const dataParsed = parseDTO(this.emotion);

                this.$service.http['admin']
                    .createEmotion(dataParsed)
                    .then((_) => {
                        this.activeSnack(true, 'Emotion cadastrado!');

                        setTimeout(() => {
                            this.goToAfterSubmitForm();
                        }, 2000);
                    })
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack(
                            false,
                            'Ocorreu um erro no processamento do endpoint!'
                        );
                    })
                    .finally((_) => {
                        this.formSubmited = false;

                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            async submitForm() {
                if (!this.validateSubmitForm()) return;

                this.$store.commit('setGlobalLoading', true);

                this.formSubmited = true;

                if (this.isEditionMode) {
                    await this.serviceUpdateEmotion();
                } else {
                    await this.serviceCreateEmotion();
                }

                this.formSubmited = false;

                this.$store.commit('setGlobalLoading', false);
            }
        }
    };
</script>

<style scoped>
    .sector {
        position: relative;
        width: 350px;
    }

    .sector-head-title {
        font-size: 26px;
    }

    .field-name {
        margin-top: 20px;
    }

    .field-submit {
        margin-top: 10px;
    }

    .field-date-hour {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .field-date-hour .field-date,
    .field-date-hour .field-hour {
        width: 48%;
    }
</style>
